import { createContext, useContext } from "react";
import { MessageProps } from "../types";
import { MessagesApi } from "./types";

export const MessagesApiContext = createContext<MessagesApi>({
  destroy: () => {
    throw new Error("Not implemented");
  },
  error: () => {
    throw new Error("Not implemented");
  },
  info: () => {
    throw new Error("Not implemented");
  },
  success: () => {
    throw new Error("Not implemented");
  },
});

export const MessagesContext = createContext<readonly MessageProps[]>(
  [],
);

export function useMessagesApi(): MessagesApi {
  return useContext(MessagesApiContext);
}
