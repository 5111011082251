import * as React from "react";
import { colors } from "../../../../constants/colors";

export const RedAlertIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0Z"
      fill={colors.coralRed}
    />
    <path
      d="M9.90001 13.5H8.10001V11.7H9.90001V13.5ZM9.90001 9.9H8.10001V4.5H9.90001V9.9Z"
      fill="white"
    />
  </svg>
);

export const EmptyIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="8"
      stroke={colors.blueGreen}
      strokeWidth="2"
    />
  </svg>
);

export const RedCrossIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" fill={colors.red} r="9" />
    <path
      clipRule="evenodd"
      d="M12.8097 6.03769L6.03844 12.8089L5.17062 11.9411L11.9418 5.16988L12.8097 6.03769Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.03611 5.19111L12.8096 11.9646L11.9418 12.8325L5.16829 6.05892L6.03611 5.19111Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export const GreyStopIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" fill={colors.darkGrey2} r="9" />
    <path
      clipRule="evenodd"
      d="M12.8571 9.61362H5.14285V8.38635H12.8571V9.61362Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export const GreenDotIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="8"
      stroke={colors.green}
      strokeWidth="2"
    />
    <circle cx="9" cy="9" fill={colors.green} r="2" />
  </svg>
);

export const GreenOkIcon = () => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      fill={colors.green}
      r="8.25"
      stroke={colors.green}
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M7.76093 11.8213L5.37271 8.35185L4.36179 9.04772L7.25556 13.2517L8.26655 13.2516L13.6386 5.44425L12.6276 4.74857L7.76093 11.8213Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export const GreyInfoIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" fill="#9EAEB6" r="11" />
    <path
      d="M11.0097 6.736C10.3057 6.736 9.90569 6.336 9.88969 5.632C9.87369 4.944 10.2897 4.576 11.0097 4.576C11.7137 4.576 12.1137 4.944 12.1297 5.632C12.1457 6.336 11.7457 6.736 11.0097 6.736ZM10.1777 16V8.32H11.8097V16H10.1777Z"
      fill="white"
    />
  </svg>
);
