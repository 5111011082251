import { ErrorBoundary } from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Error500Page } from "./components/ErrorPage";
import ThemeProvider from "./components/ThemeProvider";
import MessagesHost from "./lib/messages/MessagesHost";
import { AxiosProvider } from "./network";
import QueryClientBound from "./network/QueryClientBound";
import patchApi from "./patchApi";
import reportWebVitals from "./reportWebVitals";
import { sentryInit } from "./sentryConfig";
import GlobalStyle from "./utils/globalStyle";

sentryInit();

const container = document.getElementById("root");
if (!container) {
  throw new Error("The element #root wasn't found");
}
const root = createRoot(container);

patchApi()
  .then(() => {
    root.render(
      <React.StrictMode>
        <ThemeProvider>
          <MessagesHost>
            <GlobalStyle />
            <ErrorBoundary fallback={<Error500Page />}>
              <QueryClientBound>
                <AxiosProvider>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </AxiosProvider>
              </QueryClientBound>
            </ErrorBoundary>
          </MessagesHost>
        </ThemeProvider>
      </React.StrictMode>,
    );
  })
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.debug);
