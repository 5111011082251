import * as React from "react";
import { BaseIconAttributes } from "./types";

export const SearchArrow: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 14 9"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.9986 5.64431L12.7157 0.125L14 1.36551L6.99827 8.125L0 1.36535L1.28463 0.125161L6.9986 5.64431Z"
      fill={mainColor}
      fillRule="evenodd"
    />
  </svg>
);
