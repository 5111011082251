import * as React from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";

export type Props = {
  width?: string;
  height?: string;
  children: React.ReactNode;
  zIndex?: number;
  onClose?: () => void;
  padding?: string;
};

const Wrapper = styled.div<{ zIndex?: number }>`
  backdrop-filter: blur(4px);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ zIndex = 100 }) => zIndex};
`;

const Bg = styled.div`
  background: ${colors.lightGrey1};
  height: 100%;
  opacity: 0.8;
  width: 100%;
`;

const Container = styled.div<{ width?: string; height?: string }>`
  background: #fff;
  border-radius: 8px;
  height: ${({ height = "auto" }) => height};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width = "auto" }) => width};
`;

const Inner = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || "96px 40px 72px"};
`;

export const Popup: React.FC<Props> = ({
  height,
  width,
  zIndex,
  children,
  padding,
  onClose = () => {},
}: Props) => {
  React.useEffect(() => {
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    // content jumping fix (because of disappearing scrollbar)
    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };
  }, []);

  return (
    <Wrapper zIndex={zIndex}>
      <Bg onClick={onClose} />
      <Container height={height} width={width}>
        <Inner padding={padding}>{children}</Inner>
      </Container>
    </Wrapper>
  );
};
