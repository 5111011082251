import React from "react";
import { useSignOut } from "../../authentication/hooks/useSignOut";
import {
  dialogTimeLength,
  localStorageKey,
  sessionLength,
  timePadding,
} from "./constants";
import DialogPopup from "./DialogPopup";
import { useRefreshSession } from "./hooks";

const SessionTimer = () => {
  const { mutate: signOut, isLoading: isSignOutLoading } =
    useSignOut();
  const { mutate: refreshSession, isLoading: isRefreshLoading } =
    useRefreshSession();
  const [startTime, setStartTime] = React.useState(
    new Date().getTime(),
  );
  const [isShowDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    // storing session start time to local storage
    // for another application tabs
    localStorage.setItem(localStorageKey, startTime.toString());
    setShowDialog(false);

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timePassed = currentTime - startTime;

      // debug:
      // const totalSeconds = Math.round(timePassed / 1000);
      // const seconds = totalSeconds % 60;
      // const minutes = (totalSeconds - seconds) / 60;
      // console.log("timePassed", `${minutes}:${seconds}`);

      // checking if another application tab was loaded or refreshed token recently
      const savedTime = parseInt(
        localStorage.getItem(localStorageKey) || "0",
        10,
      );
      if (savedTime && startTime !== savedTime) {
        // sync timing if so
        clearInterval(interval);
        setStartTime(savedTime);
      }

      if (timePassed >= sessionLength) {
        // session is definitely over
        clearInterval(interval);
      }

      if (
        timePassed >=
        sessionLength - timePadding - dialogTimeLength
      ) {
        setShowDialog(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  const refreshTimeout = () => {
    // hide "Keep me signed in" dialog
    setShowDialog(false);

    // restart timer
    setStartTime(new Date().getTime());

    refreshSession();
  };

  const onLogInClick = () => {
    signOut();
  };

  return isShowDialog ? (
    <DialogPopup
      isButtonLoading={isSignOutLoading || isRefreshLoading}
      onKeepMeClick={refreshTimeout}
      onLogInClick={onLogInClick}
      timeoutTime={startTime + sessionLength - timePadding}
    />
  ) : null;
};

export default SessionTimer;
