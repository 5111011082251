import { FC, SVGAttributes } from "react";
import * as React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../constants/colors";
import { BaseIconAttributes } from "./types";

const loadingCircle = keyframes`
  to {
    transform: rotate(360deg)
  }
`;

type SpinnerProps = SVGAttributes<SVGElement>;

const Svg = styled.svg<SpinnerProps>`
  animation: ${loadingCircle} 1s infinite linear;
`;

export const Spinner: FC<BaseIconAttributes> = ({
  width = "26",
  height = "26",
  mainColor = "currentColor",
  ...restProps
}) => (
  <Svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 26 26"
    width={width}
  >
    <path
      clipRule="evenodd"
      d="M13.003 25.9995C20.1811 25.9994 26.0001 20.1793 26.0001 12.9998C26.0001 5.82025 20.1811 8.8069e-05 13.0029 9.99478e-10L13.003 3.46334C13.5555 3.46279 14.1157 3.51045 14.6787 3.60973C19.8637 4.52399 23.3257 9.46915 22.4113 14.6551C21.5938 19.2915 17.555 22.5506 13.003 22.5359V25.9995Z"
      fill={colors.grey}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.7453 0.021137C13.4978 0.00708892 13.2484 -3.05176e-05 12.9973 -3.05176e-05C5.8191 -3.05176e-05 0 5.82016 0 12.9997C0 13.1126 0.00143846 13.2252 0.00429806 13.3374L3.48601 12.8616C3.55989 7.65931 7.79924 3.46489 13.0181 3.46489C13.2627 3.46489 13.5053 3.47411 13.7453 3.49222V0.021137Z"
      fill="url(#paint0_linear_247_1102)"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_247_1102"
        x1="12.9973"
        x2="5.68937"
        y1="3.91808"
        y2="15.1586"
      >
        <stop stopColor={colors.grey} />
        <stop offset="1" stopColor={colors.grey} stopOpacity="0" />
      </linearGradient>
    </defs>
  </Svg>
);
