/* eslint-disable sort-keys */
import { createContext } from "react";
import Message from "../Message";
import { MessagesConfig } from "./types";

export const messagesConfigDefaultValue: MessagesConfig = {
  MessageComponent: Message,
  duration: 7,
};
export const MessagesConfigContext = createContext(
  messagesConfigDefaultValue,
);
