import * as Sentry from "@sentry/react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { isRight } from "fp-ts/Either";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import JSONApiErrorsPayloadC, {
  JSONApiErrorsException,
} from "./jsonApiV2/models/core/JSONApiErrorsPayload";
import QueryClientProviderBound from "./QueryClientBound";

const AxiosContext = createContext<AxiosInstance>(Axios);

export const AxiosProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const axios = useMemo(
    () => constructAxios(queryClient),
    [queryClient],
  );

  return (
    <AxiosContext.Provider value={axios}>
      {children}
    </AxiosContext.Provider>
  );
};

export function useAxios(): AxiosInstance {
  return useContext(AxiosContext);
}

function prefillXCSRFToken<T extends AxiosRequestConfig>(
  queryClient: QueryClient,
  config: T,
): T {
  try {
    const cookieRequestCache = queryClient
      .getQueryCache()
      .find(["cookie"]);
    if (cookieRequestCache) {
      const { state } = cookieRequestCache;
      const cookieData = state.data as
        | {
            csrfToken?: string | null;
          }
        | undefined;
      if (cookieData?.csrfToken) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,no-param-reassign
        config.headers = {
          ...config.headers,
          "X-CSRF-Token": cookieData.csrfToken,
        };
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return config;
}

export function constructAxios(
  queryClient: QueryClient,
): AxiosInstance {
  const mswEnabled =
    import.meta.env.NODE_ENV === "test" ||
    import.meta.env.VITE_MSW_ENABLED === "enabled" ||
    !!import.meta.env.STORYBOOK;

  const baseURL = mswEnabled
    ? import.meta.env.VITE_BACKEND
    : `${import.meta.env.VITE_API_HOST || ""}/api`;
  // eslint-disable-next-line no-console
  // console.debug("base URL", baseURL);
  const axios = Axios.create({
    baseURL,
    withCredentials: mswEnabled ? undefined : true,
  });
  const XCSRFTokenInterceptor = <T extends AxiosRequestConfig>(
    config: T,
  ): T => prefillXCSRFToken(queryClient, config);
  const ErrorsParser = async (error: unknown) => {
    // TODO: errors
    if (Axios.isAxiosError(error) && error.response) {
      if (
        error.response.status !== 401 &&
        error.response.status !== 422
      ) {
        Sentry.captureException(error);
      }

      const decoded = JSONApiErrorsPayloadC.decode(
        error.response.data,
      );
      if (isRight(decoded)) {
        let { message } = error;
        const { errors } = decoded.right;
        const requestConfig = error.config as
          | (InternalAxiosRequestConfig & { retryCount?: number })
          | undefined;
        const retryCount = requestConfig?.retryCount || 0;

        // if stale csrf token
        if (
          errors[0].code === "invalid_authenticity_token" &&
          requestConfig &&
          retryCount < 2
        ) {
          // try to refetch token
          try {
            await queryClient.refetchQueries(["cookie"]);
          } catch (tokenRefetchError) {
            return Promise.reject(tokenRefetchError);
          }

          requestConfig.retryCount = retryCount + 1;
          // retry the original request
          return axios.request(requestConfig);
        }

        if (
          errors[0].source?.pointer === "data/attributes/base" &&
          errors[0].title
        ) {
          message = errors[0].title;
        }
        return Promise.reject(
          new JSONApiErrorsException(message, errors),
        );
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
  axios.interceptors.request.use(XCSRFTokenInterceptor);
  axios.interceptors.response.use(undefined, ErrorsParser);

  return axios;
}
export { QueryClientProviderBound };
