import { CSSProperties } from "react";
import styled from "styled-components";
import { fonts } from "../../constants/fonts";

interface ExtraProps {
  level: "h1" | "h2" | "h3";
}

const FONT_SIZE: Record<
  "h1" | "h2" | "h3",
  CSSProperties["fontSize"]
> = {
  h1: "24px",
  h2: "24px",
  h3: "24px",
};
const LINE_HEIGHT: Record<
  "h1" | "h2" | "h3",
  CSSProperties["lineHeight"]
> = {
  h1: "28px",
  h2: "28px",
  h3: "28px",
};
const FONT_WEIGHT: Record<
  "h1" | "h2" | "h3",
  CSSProperties["fontWeight"]
> = {
  h1: 500,
  h2: 500,
  h3: 400,
};
const FONT_FAMILY: Record<
  "h1" | "h2" | "h3",
  CSSProperties["fontFamily"]
> = {
  h1: fonts.alt,
  h2: fonts.faktum,
  h3: fonts.faktum,
};
const Title = styled.h1.attrs((props: ExtraProps) => ({
  as: props.level,
}))<ExtraProps>`
  color: black;
  font-family: ${({ level }) => FONT_FAMILY[level]};
  font-size: ${({ level }) => FONT_SIZE[level]};
  line-height: ${({ level }) => LINE_HEIGHT[level]};
  font-weight: ${({ level }) => FONT_WEIGHT[level]};
  font-style: normal;
`;

export default Title;
