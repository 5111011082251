import { FC, ReactNode } from "react";
import { ThemeProvider as BaseThemeProvider } from "styled-components";

const THEME = {};
const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <BaseThemeProvider theme={THEME}>{children}</BaseThemeProvider>
  );
};

export default ThemeProvider;
