import { FC } from "react";
import { Outlet, useNavigation } from "react-router";
import styled, { keyframes } from "styled-components";
import { colors } from "../constants/colors";

// just fill it fast from the start and then slow down until stop when reach 90% of width
const keyframesStr = new Array(21)
  .fill(null)
  .map((item, index) => {
    const x = index * 5;
    const y = 1000 / (-x - 10) + 100;
    return `${x}% { width: ${y}%; }`;
  })
  .join("\n\r");

const loadingAnimation = keyframes`${keyframesStr}`;

const Loader = styled.div`
  animation: ${loadingAnimation} 20s 1 linear;
  animation-fill-mode: forwards;
  background: ${colors.blueGreen};
  height: 5px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const RouteLoader: FC = () => {
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";

  return (
    <>
      {isLoading && <Loader />}
      <Outlet />
    </>
  );
};

export default RouteLoader;
