import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Magnifier: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 15 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.09295 9.09295L9.2411 8.94481L13 12.7037L12.7037 13L8.9448 9.2411L9.09295 9.09295ZM9.09295 9.09295C8.23505 9.95086 7.04986 10.4815 5.74074 10.4815C3.1225 10.4815 1 8.35898 1 5.74074C1 3.1225 3.1225 1 5.74074 1C8.35898 1 10.4815 3.1225 10.4815 5.74074C10.4815 7.04986 9.95086 8.23505 9.09295 9.09295Z"
      stroke={mainColor}
      strokeLinecap="square"
      strokeWidth="1.8"
    />
  </svg>
);
