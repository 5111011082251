import { motion } from "framer-motion";
import { FC, useEffect } from "react";
import styled from "styled-components";
import {
  GreenOkIcon,
  RedCrossIcon,
  GreyInfoIcon,
} from "../../../authorizations/components/AuthorizationsPage/ExtendedStatus/Icons";
import { colors } from "../../../constants/colors";
// import Icon from "../../Icon";
import { useMessagesApi } from "../MessagesHost/context";
import { MessageProps } from "../types";

const Container = styled(motion.div)`
  background: ${colors.extra};
  border-radius: 8px;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  display: grid;
  font-weight: 400;
  gap: 10px;
  grid-template-columns: 22px auto;
  max-width: 400px;
  padding: 20px;
  pointer-events: auto;
  position: relative;

  svg {
    height: 22px;
    width: 22px;
  }
`;

const Text = styled.div`
  color: #000;
  font-size: 15px;
  line-height: 20px;
  padding-top: 1px;
  text-align: left;
`;

const Message: FC<MessageProps> = (props) => {
  const { className, content, duration, messageKey, style, type } =
    props;
  const messagesApi = useMessagesApi();

  useEffect(() => {
    let timeoutId: unknown | undefined;

    if (duration > 0) {
      timeoutId = setTimeout(() => {
        messagesApi.destroy(messageKey);
      }, duration * 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId as number);
      }
    };
  }, [duration, messageKey, messagesApi]);
  return (
    <Container
      animate={{ opacity: 1 }}
      className={className}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      initial={{ opacity: 0 }}
      onClick={() => {
        messagesApi.destroy(messageKey);
      }}
      role="alert"
      style={style}
    >
      {type === "success" && <GreenOkIcon />}
      {type === "error" && <RedCrossIcon />}
      {type === "info" && <GreyInfoIcon />}

      <Text>{content}</Text>
    </Container>
  );
};

export default Message;
