import React, { CSSProperties, ReactNode } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants/colors";
import { fonts } from "../../constants/fonts";
import mediaQuery from "../../utils/mediaQuery";
import Icon from "../Icon";
import {
  CoreButton,
  CoreButtonIcon,
  CoreButtonInner,
} from "./CoreButton";

export type ButtonKind = "filled" | "outlined" | "tonal";
export type ButtonSize = "normal" | "small";

export interface ButtonExtraProps {
  icon?: ReactNode;
  loading?: boolean;
  kind?: ButtonKind;
  size?: ButtonSize;
  resetWidth?: boolean;
}

const Background: Record<ButtonKind, CSSProperties["background"]> = {
  filled: colors.primary,
  outlined: "transparent",
  tonal: colors.background,
};
const BackgroundHover: Record<
  ButtonKind,
  CSSProperties["background"]
> = {
  filled: colors.primaryHover,
  outlined: colors.backgroundHover,
  tonal: colors.backgroundHover,
};
const OpacityPressed: Record<ButtonKind, CSSProperties["opacity"]> = {
  filled: "0.9",
  outlined: "0.8",
  tonal: "0.8",
};
const OpacityLoading: Record<ButtonKind, CSSProperties["opacity"]> = {
  filled: "0.6",
  outlined: "0.6",
  tonal: "0.6",
};
const OpacityDisabled: Record<ButtonKind, CSSProperties["opacity"]> =
  {
    filled: "0.2",
    outlined: "0.4",
    tonal: "0.8",
  };
const BoxShadow: Record<ButtonKind, CSSProperties["boxShadow"]> = {
  filled: "none",
  outlined: `inset 0 0 0 1px ${colors.primary}`,
  tonal: "none",
};
const BoxShadowHover: Record<ButtonKind, CSSProperties["boxShadow"]> =
  {
    filled: "none",
    outlined: `inset 0 0 0 1px ${colors.primaryHover}`,
    tonal: "none",
  };
const Color: Record<ButtonKind, CSSProperties["color"]> = {
  filled: colors.white,
  outlined: colors.primary,
  tonal: colors.primary,
};
const ColorHover: Record<ButtonKind, CSSProperties["color"]> = {
  filled: colors.white,
  outlined: colors.primaryHover,
  tonal: colors.primary,
};
const FontSize: Record<ButtonSize, CSSProperties["fontSize"]> = {
  normal: "20px",
  small: "16px",
};

const LineHeight: Record<ButtonSize, CSSProperties["lineHeight"]> = {
  normal: "24px",
  small: "20px",
};
const Padding: Record<ButtonSize, CSSProperties["padding"]> = {
  normal: "0 20px",
  small: "0 12px",
};
const Height: Record<ButtonSize, CSSProperties["height"]> = {
  normal: "50px",
  small: "40px",
};

export const Button = styled(CoreButton)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      // do not pass these props to the CoreButton tag and get rid of "Warning: Received `false` for a non-boolean attribute `loading`."
      // CoreButton does not want to know about "loading" anyway
      !["loading", "size", "kind", "resetWidth"].includes(prop) &&
      defaultValidatorFn(prop),
  })
  .attrs(
    ({
      kind = "filled",
      size = "normal",
      loading,
      icon,
      resetWidth = false,
    }: ButtonExtraProps) => ({
      icon: loading ? <Icon type="spinner" /> : icon || undefined,
      kind,
      loading,
      resetWidth,
      size,
    }),
  )
  .withConfig({
    shouldForwardProp: (prop) =>
      prop !== "resetWidth" && prop !== "kind" && prop !== "loading",
  })`
  background: ${({ kind }) => Background[kind]};
  border: none;
  box-shadow: ${({ kind }) => BoxShadow[kind]};
  box-sizing: border-box;
  border-radius: 8px;
  color: ${({ kind }) => Color[kind]};
  cursor: pointer;
  font-size: ${({ size }) => FontSize[size]};
  font-family: ${fonts.faktum};
  font-weight: 500;
  line-height: ${({ size }) => LineHeight[size]};
  padding: ${({ size }) => Padding[size]};
  height: ${({ size }) => Height[size]};
  white-space: nowrap;
  pointer-events: ${({ loading }) => (loading ? "none" : "all")};
  opacity: ${({ loading, kind }) =>
    loading ? OpacityLoading[kind] : "1"};
  width: ${({ resetWidth }) => (resetWidth ? undefined : "100%")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.3s ease;

  &:hover {
    background: ${({ kind }) => BackgroundHover[kind]};
    box-shadow: ${({ kind }) => BoxShadowHover[kind]};
    color: ${({ kind }) => ColorHover[kind]};
  }

  &:active {
    opacity: ${({ kind }) => OpacityPressed[kind]};
    background: ${({ kind }) =>
      kind === "filled" ? Background[kind] : colors.backgroundHover};
  }

  &[disabled] {
    opacity: ${({ kind }) => OpacityDisabled[kind]};
    cursor: not-allowed;
    background: ${({ kind }) => Background[kind]};
    color: ${({ kind }) =>
      kind === "tonal" ? colors.primary3 : Color[kind]};

    ${CoreButtonIcon} {
      opacity: ${({ kind }) => (kind === "tonal" ? "0.4" : "1")};
    }
  }

  ${CoreButtonInner} {
    align-items: center;
    display: flex;
    white-space: nowrap;
  }

  ${CoreButtonIcon} {
    align-items: center;
    display: flex;
    flex: 0;
    line-height: 1;
    margin-right: 7px;
    white-space: nowrap;
  }

  ${mediaQuery(
    "noTouch",
    css`
      cursor: pointer;
    `,
  )}

  @media print {
    display: none;
  }
`;
