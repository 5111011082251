import { FC, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";

type SizeMode = "fit-content" | "fullscreen" | "stretch";
export interface SpinProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  mode?: SizeMode;
  children?: React.ReactNode;
}

const fitContentStyles = css`
  height: fit-content;
  width: fit-content;
`;
const stretchToParentContent = css`
  height: 100%;
  width: 100%;
`;
const Container = styled.div<{ mode: SizeMode }>`
  box-sizing: border-box;
  display: grid;
  ${({ mode }) =>
    mode === "fit-content"
      ? fitContentStyles
      : stretchToParentContent};
`;
const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  grid-row: 1;
  justify-content: center;
`;
const ContentContainer = styled.div.withConfig<{ loading: boolean }>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["loading"].includes(prop) && defaultValidatorFn(prop),
})`
  grid-column: 1;
  grid-row: 1;
  opacity: ${({ loading }) => (loading ? 0.3 : undefined)};
`;

const Spin: FC<SpinProps> = (props) => {
  const {
    children,
    loading = true,
    mode = "fit-content",
    ...spinProps
  } = props;
  const isNestedPattern = children !== undefined;

  const spinElement = <Icon type="spinner" />;

  if (mode === "fullscreen" || isNestedPattern) {
    return (
      <Container mode={mode} {...spinProps}>
        {loading && (
          <SpinnerContainer>{spinElement}</SpinnerContainer>
        )}
        <ContentContainer loading={loading}>
          {children}
        </ContentContainer>
      </Container>
    );
  }
  return spinElement;
};

export default Spin;
