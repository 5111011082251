/* eslint-disable no-console */
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import React, { FC, ReactNode, useState } from "react";

export function buildQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
    logger: {
      // ✅ no more errors on the console for tests
      // https://react-query.tanstack.com/guides/testing#turn-off-network-error-logging
      error:
        import.meta.NODE_ENV === "test" ? () => {} : console.error,
      log: console.log,
      warn: console.warn,
    },
  });
}

const QueryClientProviderBound: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [queryClient] = useState(() => buildQueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};

export default QueryClientProviderBound;
