import { useMutation } from "@tanstack/react-query";
import { useMessagesApi } from "../../lib/messages/MessagesHost/context";
import { useAxios } from "../../network";
import { PATHS } from "../../router/PATHS";
import broadcastChannel from "../../utils/broadcastChannel";

export function useSignOut() {
  const axios = useAxios();
  const messagesApi = useMessagesApi();

  return useMutation(
    async () => {
      await axios.delete("/v1/session", {
        validateStatus: (status) => status === 204 || status === 401,
      });
    },
    {
      onError: () => {
        // will try to show sign in page anyway
        broadcastChannel.postMessage("logout");
        window.location.pathname = PATHS.signIn;
      },
      onSuccess: () => {
        broadcastChannel.postMessage("logout");
        messagesApi.info({
          content: "Signed out successfully.",
        });
        window.location.pathname = PATHS.signIn;
      },
    },
  );
}
