import { FC } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router";
import styled from "styled-components";
import { fonts } from "../../constants/fonts";
import Link from "../../lib/Link";
import Logo from "../../lib/Logo";
import Title from "../../lib/Title";

const LogoContainer = styled.div`
  left: 30px;
  position: absolute;
  top: 26px;
  svg {
    height: 25px;
    width: 166px;
  }
`;

const ErrorCode = styled.h2`
  color: #909090;
  font-family: ${fonts.alt}, sans-serif;
  font-size: 24px;
  line-height: 24px;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

const Container = styled.div`
  background: #f5f7f7;
  min-height: 100%;

  ${Link} {
    font-size: 16px;
  }

  ${Title} {
    margin: 0 0 14px;
  }

  p {
    color: #909090;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 50px;
  }

  ${ErrorCode} {
    margin: 0 0 10px;
  }
`;

export const NotFoundPage: FC = () => {
  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Content>
        <ErrorCode>404</ErrorCode>
        <Title level="h1">
          The page you were looking for doesn’t exist
        </Title>
        <p>
          You may have mistyped the address or the page may have moved
        </p>
        <Link to="/">Go to main page</Link>
      </Content>
    </Container>
  );
};

export const Error500Page: FC = () => {
  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Content>
        <ErrorCode>500</ErrorCode>
        <Title level="h1">
          We&#39;re sorry, but something went wrong
        </Title>
        <Link to="/">Return home</Link>
      </Content>
    </Container>
  );
};

export const ErrorBoundary: FC = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return (
      <h1>
        {error.status} {error.statusText}
      </h1>
    );
  }
  if (error instanceof Error) {
    return <h1>{error.message}</h1>;
  }
  return null;
};

export default NotFoundPage;
