import React from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import { Button } from "../../lib/Button";
import Icon from "../../lib/Icon";
import { Popup } from "../../lib/Popup";

interface Props {
  onKeepMeClick: () => void;
  onLogInClick: () => void;
  timeoutTime: number;
  isButtonLoading: boolean;
}

const f00 = (n: number) => (n < 10 ? `0${n}` : n);

const getTime = (raw: number) => {
  if (raw <= 0) return "00:00";

  const totalSeconds = Math.round(raw / 1000);
  const seconds = totalSeconds % 60;
  const minutes = (totalSeconds - seconds) / 60;

  return `${f00(minutes)}:${f00(seconds)}`;
};

const Centered = styled.div`
  text-align: center;
`;

const Text = styled.div`
  font-family: "Faktum", sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`;

const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 25px auto 73px;
`;

const Time = styled.div`
  font-family: "Faktum", sans-serif;
  font-size: 40px;
  line-height: 40px;
  margin-left: 8px;
  transform: translateY(2px);
  width: 110px;
`;

const DialogPopup: React.FC<Props> = ({
  onKeepMeClick,
  onLogInClick,
  timeoutTime,
  isButtonLoading,
}: Props) => {
  const [time, setTime] = React.useState(
    timeoutTime - new Date().getTime(),
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      const newTime = timeoutTime - new Date().getTime();
      setTime(newTime);
      if (newTime <= 0) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeoutTime]);

  return (
    <Popup width="364px">
      {time <= 0 ? (
        <Centered>
          <Text>
            Your session <br />
            has expired
          </Text>
          <Flex>
            <Icon
              height="32px"
              mainColor={colors.brand}
              type="popupIconStop"
              width="32px"
            />
          </Flex>
          <Button
            kind="filled"
            loading={isButtonLoading}
            onClick={onLogInClick}
          >
            Log in
          </Button>
        </Centered>
      ) : (
        <Centered>
          <Text>
            Your session will <br />
            expire in
          </Text>
          <Flex>
            <Icon
              height="32px"
              mainColor={colors.greyBlue}
              type="popupIconWatch"
              width="32px"
            />
            <Time>{getTime(time)}</Time>
          </Flex>
          <Button
            kind="filled"
            loading={isButtonLoading}
            onClick={onKeepMeClick}
          >
            Keep me signed in
          </Button>
        </Centered>
      )}
    </Popup>
  );
};

export default DialogPopup;
