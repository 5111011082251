import * as React from "react";
import { BaseIconAttributes } from "./types";

export const PopupIconWatch: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <circle cx="16" cy="16" fill={mainColor} r="16" />
    <path
      clipRule="evenodd"
      d="M14.25 6h3.5v9.4775l9.0918 4.9883-1.6836 3.0684L14.25 17.5493V6Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
