import { CSSProperties } from "react";

export type ColorName =
  | "background"
  | "background2"
  | "background3"
  | "backgroundHover"
  | "black"
  | "blueGreen"
  | "brand"
  | "brownBlack"
  | "brownLightGrey1"
  | "coralRed"
  | "darkGrey1"
  | "darkGrey2"
  | "error"
  | "extra"
  | "green"
  | "grey"
  | "greyBlue"
  | "lightGrey1"
  | "lightGrey2"
  | "neutral"
  | "primary"
  | "primary1"
  | "primary2"
  | "primary3"
  | "primary4"
  | "primaryHover"
  | "red"
  | "secondary"
  | "secondaryHover"
  | "success"
  | "white";

export const colors: Record<
  ColorName,
  NonNullable<CSSProperties["color"]>
> = {
  background: "#F5F7F7",
  background2: "#F5F7F799",
  background3: "#F5F7F766",
  backgroundHover: "#E8EDED",
  black: "#000000",
  blueGreen: "#2D4F61",
  brand: "#FF7468",
  brownBlack: "#4A4A4A",
  brownLightGrey1: "#E0DDD9",
  coralRed: "#FF7468",
  darkGrey1: "#4C4C4C",
  darkGrey2: "#909090",
  error: "#EB5757",
  extra: "#FFFED1",
  green: "#21CD97",
  grey: "#C4C4C4",
  greyBlue: "#9EAEB6",
  lightGrey1: "#E5E5E5",
  lightGrey2: "#F5F5F5",
  neutral: "#D8A521",
  primary: "#2D4F61",
  primary1: "#2D4F61CC",
  primary2: "#2D4F6199",
  primary3: "#2D4F6166",
  primary4: "#2D4F6133",
  primaryHover: "#004862",
  red: "#EB5757",
  secondary: "#3E4FD6",
  secondaryHover: "#000000",
  success: "#21CD97",
  white: "#FFFFFF",
};
