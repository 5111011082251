import * as React from "react";
import { BaseIconAttributes } from "./types";

export const ChevronDown: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 11 8"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.69509 4.36682L9.53182 0.957275L10.8604 2.45226L5.69475 7.04273L0.531624 2.45209L1.86054 0.957446L5.69509 4.36682Z"
      fill={mainColor}
      fillRule="evenodd"
    />
  </svg>
);
