import { FC } from "react";
import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";
import FaktumMediumWoff from "../assets/fonts/38C82F_0_0.woff";
import FaktumMediumWoff2 from "../assets/fonts/38C82F_0_0.woff2";
import FaktumNormalWoff from "../assets/fonts/38C82F_1_0.woff";
import FaktumNormalWoff2 from "../assets/fonts/38C82F_1_0.woff2";
import DuplicateSansBoldWoff from "../assets/fonts/DuplicateSans-Bold-Web.woff";
import DuplicateSansBoldWoff2 from "../assets/fonts/DuplicateSans-Bold-Web.woff2";

export const BaseGlobalStyles = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: "Duplicate Sans";
    src: url("${DuplicateSansBoldWoff2}") format("woff2"),
    url("${DuplicateSansBoldWoff}") format("woff");
    font-weight: bold;
  }

  @font-face {
    font-family: "Faktum";
    font-weight: 500;
    src: url("${FaktumMediumWoff2}") format("woff2"),
    url("${FaktumMediumWoff}") format("woff");
  }

  @font-face {
    font-family: "Faktum";
    font-weight: 400;
    src: url("${FaktumNormalWoff2}") format("woff2"),
    url("${FaktumNormalWoff}") format("woff");
  }

  body {
    font-family: Faktum, sans-serif;
    font-size: 15px;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, #root {
    height: 1px;
    min-height: 100%;
  }

  // https://aykevl.nl/2014/09/fix-jumping-scrollbar
  @media screen and (min-width: 960px) {
    html {
      margin-left: calc(100vw - 100%);
      margin-right: 0;
    }
  }
  
  :root {
    // for debugging
    --project-name: "network"

    --font-alt: "Duplicate Sans", sans-serif;
    --font-faktum: "Faktum", sans-serif;
    --font-main: "Roboto", sans-serif;
  }



`;

export const PrintGlobalStyles = createGlobalStyle`
  @page {
    size: A4;
    margin: 0 10mm;
  }
  
  @media print {
    .ReactQueryDevtools {
      display: none;
    }
  }
`;

const GlobalStyles: FC = () => (
  <>
    <BaseGlobalStyles />
    <PrintGlobalStyles />
  </>
);

export default GlobalStyles;
