import { FC, useContext } from "react";
import styled from "styled-components";
import { MessagesConfigContext } from "../MessagesConfigProvider/context";
import { MessagesContext } from "../MessagesHost/context";

const Container = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  right: 25px;
  top: 25px;
  z-index: 100;
`;

const MessagesContainer: FC<{ className?: string }> = ({
  className,
}) => {
  const messages = useContext(MessagesContext);
  const { MessageComponent } = useContext(MessagesConfigContext);

  return (
    <Container className={className}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {messages.map((message) => (
        <MessageComponent {...message} key={message.messageKey} />
      ))}
    </Container>
  );
};

export default MessagesContainer;
