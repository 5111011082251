import { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../router/PATHS";

const INITIAL_LOCATION_KEY = "initialLocation";
type IntroLocationDescription = {
  pathname: string;
  search: string;
  state: unknown;
};

export const NavigateToSignIn: FC = () => {
  const location = useLocation();
  const { pathname, search } = location;
  // eslint-disable-next-line prefer-destructuring
  const state: unknown = location.state;

  useEffect(() => {
    sessionStorage.setItem(
      INITIAL_LOCATION_KEY,
      JSON.stringify({
        pathname,
        search,
        state,
      } as IntroLocationDescription),
    );
  }, [pathname, search, state]);
  return <Navigate replace to={PATHS.signIn} />;
};

export const RestoreLocation: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const introLocationStr = sessionStorage.getItem(
      INITIAL_LOCATION_KEY,
    );
    sessionStorage.removeItem(INITIAL_LOCATION_KEY);
    if (introLocationStr) {
      try {
        // FIXME: after registration it restore a wrong page...
        const initialLocation = JSON.parse(
          introLocationStr,
        ) as IntroLocationDescription;
        navigate(
          `${initialLocation.pathname}${initialLocation.search}`,
          {
            replace: true,
            state: initialLocation.state,
          },
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(
          "Can not restore initial location. Skip restoring",
          e,
        );
      }
    }
  });
  return (
    <>
      <Outlet />
    </>
  );
};
