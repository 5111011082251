async function checkHasNewVersion(): Promise<boolean> {
  const resp = await fetch("/version.txt");
  if (!resp.ok) {
    return false;
  }
  try {
    const version = await resp.text();
    const currentVersion = import.meta.env.VITE_BUILD_TIMESTAMP;
    return (
      !!version && !!currentVersion && version !== currentVersion
    );
  } catch (error) {
    return false;
  }
}

export default checkHasNewVersion;
