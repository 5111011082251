import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Print: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 21 18"
    width={width}
  >
    <path
      clipRule="evenodd"
      d="M4.22973 5.14849L4.28944 0.311646H16.7696V5.14849H18.0235C18.6887 5.14849 19.3266 5.41272 19.7969 5.88305C20.2673 6.35339 20.5315 6.9913 20.5315 7.65646V11.4184C20.5315 12.0836 20.2673 12.7215 19.7969 13.1918C19.3266 13.6621 18.6887 13.9264 18.0235 13.9264H16.7696V17.6883H4.22973V13.9264H2.97574C2.31059 13.9264 1.67268 13.6621 1.20234 13.1918C0.732006 12.7215 0.467773 12.0836 0.467773 11.4184V7.65646C0.467773 6.9913 0.732006 6.35339 1.20234 5.88305C1.67268 5.41272 2.31059 5.14849 2.97574 5.14849H4.22973ZM6.73769 8.91044C6.07254 8.91044 5.43463 9.17467 4.96429 9.64501C4.49396 10.1153 4.22973 10.7533 4.22973 11.4184V12.6724H2.97574C2.64316 12.6724 2.32421 12.5403 2.08904 12.3051C1.85387 12.0699 1.72176 11.751 1.72176 11.4184V7.65646C1.72176 7.32388 1.85387 7.00492 2.08904 6.76975C2.32421 6.53459 2.64316 6.40247 2.97574 6.40247H18.0235C18.3561 6.40247 18.6751 6.53459 18.9102 6.76975C19.1454 7.00492 19.2775 7.32388 19.2775 7.65646V11.4184C19.2775 11.751 19.1454 12.0699 18.9102 12.3051C18.6751 12.5403 18.3561 12.6724 18.0235 12.6724H16.7696V11.4184C16.7696 10.7533 16.5053 10.1153 16.035 9.64501C15.5647 9.17467 14.9268 8.91044 14.2616 8.91044H6.73769ZM2.85935 7.67058C3.03411 7.49582 3.27114 7.39764 3.51829 7.39764C3.76544 7.39764 4.00247 7.49582 4.17723 7.67058C4.35199 7.84534 4.45017 8.08237 4.45017 8.32952C4.45017 8.57667 4.35199 8.81369 4.17723 8.98846C4.00247 9.16322 3.76544 9.2614 3.51829 9.2614C3.27114 9.2614 3.03411 9.16322 2.85935 8.98846C2.68459 8.81369 2.58641 8.57667 2.58641 8.32952C2.58641 8.08237 2.68459 7.84534 2.85935 7.67058Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);
