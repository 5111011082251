import * as React from "react";
import { BaseIconAttributes } from "./types";

export const PopupIconStop: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <circle
      cx="16"
      cy="16"
      fill={mainColor}
      r="15"
      stroke={mainColor}
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      d="M24 17.5H8V14h16v3.5Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
