import { Link as BaseLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../constants/colors";

const linkStyles = css`
  color: ${colors.secondary};
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  &:hover {
    color: ${colors.secondaryHover};
  }
`;

const Link = styled(BaseLink)`
  ${linkStyles}
`;

// export const ExternalLink: FC<{
//   className?: string;
//   to: string;
//   children: ReactNode;
// }> = ({ className, to, children, ...restProps }) => {
//   return (
//     <a className={className} href={to}>
//       {children}
//     </a>
//   );
// };

export const ExternalLink = styled.a`
  ${linkStyles}
`;

export default Link;
