import * as t from "io-ts";
import JSONApiErrorC from "./JSONApiError";

const JSONApiErrorsPayloadC = t.type({
  errors: t.array(JSONApiErrorC),
});

export type JSONApiErrorsPayload = t.TypeOf<
  typeof JSONApiErrorsPayloadC
>;
export default JSONApiErrorsPayloadC;

export class JSONApiErrorsException extends Error {
  constructor(
    message: string,
    public readonly errors: JSONApiErrorsPayload["errors"],
  ) {
    super(message);
  }
}
