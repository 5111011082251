import { ComponentType, FC, Fragment } from "react";
import styled from "styled-components";
import { Check } from "./Check";
import { CheckSlim } from "./CheckSlim";
import { ChevronDown } from "./ChevronDown";
import { Cross } from "./Cross";
import { Exit } from "./Exit";
import { Magnifier } from "./Magnifier";
import { Minus } from "./Minus";
import { PasswordEyeClosed } from "./PasswordEyeClosed";
import { PasswordEyeOpen } from "./PasswordEyeOpen";
import { PopupIconStop } from "./PopupIconStop";
import { PopupIconWatch } from "./PopupIconWatch";
import { Print } from "./Print";
import { SearchArrow } from "./SearchArrow";
import { Spinner } from "./Spinner";
import { BaseIconAttributes } from "./types";

/**
 * useful for rendering stories
 */
export const ICON_TYPES = [
  "check" as const,
  "checkSlim" as const,
  "chevronDown" as const,
  "cross" as const,
  "magnifier" as const,
  "minus" as const,
  "passwordEyeClosed" as const,
  "passwordEyeOpen" as const,
  "popupIconStop" as const,
  "popupIconWatch" as const,
  "spinner" as const,
  "searchArrow" as const,
  "exit" as const,
  "print" as const,
];
type ArrayElementType<T> = T extends Array<infer Item>
  ? Item
  : unknown;

export type IconType = ArrayElementType<typeof ICON_TYPES>;

export interface IconProps extends BaseIconAttributes {
  type: IconType;
}

const iconsMapper: Record<
  IconType,
  ComponentType<BaseIconAttributes>
> = {
  check: Check,
  checkSlim: CheckSlim,
  chevronDown: ChevronDown,
  cross: Cross,
  exit: Exit,
  magnifier: Magnifier,
  minus: Minus,
  passwordEyeClosed: PasswordEyeClosed,
  passwordEyeOpen: PasswordEyeOpen,
  popupIconStop: PopupIconStop,
  popupIconWatch: PopupIconWatch,
  print: Print,
  searchArrow: SearchArrow,
  spinner: Spinner,
};

const Icon: FC<IconProps> = ({ type, ...restProps }) => {
  const IconC = iconsMapper[type] || Fragment;

  return <IconC {...restProps} />;
};

export default styled(Icon)``;
