import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Exit: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 15 17"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.12804V1.70487C1 1.30346 1.31852 1 1.70556 1H13.2833C13.6704 1 14 1.30346 14 1.70487V15.249C14 15.6504 13.6704 16 13.2833 16H1.70556C1.31852 16 1 15.6504 1 15.249V12.872"
      stroke={mainColor}
      strokeWidth="1.5"
    />
    <path
      d="M5.82959 4.76825L9.46131 8.47695L5.82959 12.1837"
      stroke={mainColor}
      strokeWidth="1.5"
    />
    <path d="M0.5 8.5H9.5" stroke={mainColor} strokeWidth="1.5" />
  </svg>
);
